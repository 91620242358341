import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import DynamicList from 'src/components/DynamicList'

import { IFundsDTVMContextProps } from '../../types'

const FundsDTVM = ({ pageContext }: IFundsDTVMContextProps) => {
  return (
    <SectionComponent
      id='investimentos-dtvm'
      SectionStyles='py-0'
    >
      <DynamicList name={pageContext.slug} path='INVESTMENTS' />
    </SectionComponent>
  )
}

export default FundsDTVM
