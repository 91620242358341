import React from 'react'
import slugify from 'slugify'
import Layout from 'src/layouts/BaseLayout'

import HeroDTVM from './sections/hero/_index'
import FundsDTVM from './sections/fund/_index'

import { IFundsDTVMContextProps } from './types'

const InvestimentFunds = ({ pageContext }: IFundsDTVMContextProps) => {
  const contentMetas = {
    slug: `/pra-voce/investimentos/fundos-de-investimento/${slugify(String(pageContext.slug), { strict: true }).toLowerCase()}/`,
    metaTitle: `${pageContext.fundTitle}` + ' | Inter',
    metaDescription: `${'Diversifique sua carteira com o ' + pageContext.fundTitle + '. Saiba mais e invista através do Banco Inter ainda hoje.'}`,
  }

  return (
    <Layout pageContext={contentMetas}>
      <HeroDTVM />
      <FundsDTVM pageContext={pageContext} />
    </Layout>
  )
}

export default InvestimentFunds
