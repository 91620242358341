import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import BgInvestimentosMobile from './../../assets/images/fundos-de-investimentos-mobile.jpg'
import BgInvestimentosDesktop from './../../assets/images/fundos-de-investimentos-desktop.jpg'

export const SectionDTVM = styled.section`
  display: flex;
  align-items: end;
  min-height: 470px;
  background-image: url(${BgInvestimentosMobile});
  background-size: cover, cover, cover;
  background-position: center, center, center;
  background-repeat: no-repeat, no-repeat, no-repeat;

  @media ${device.tablet} {
    background-image: url(${BgInvestimentosDesktop});
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:before {
    content: '';
    background-image: linear-gradient(to right, rgba(140, 47, 12, 0.8) 60%, rgba(140, 47, 12, 0.5));
    height: 100%;
    position: absolute;
    width: 100%;
  }

  > .container {
    padding-bottom: 10px;
    padding-top: 90px;
    transition: all 2000ms ease;
    
    @media (min-width: map-get($inter-grid-breakpoints, md)) {
      padding-left: 10px;
    }
  }
`
