import React from 'react'
import LogoInterInvest from 'inter-frontend-svgs/lib/Icons/inter-invest-white'

import { SectionDTVM } from './styles'

const HeroDTVM = () => {
  return (
    <SectionDTVM
      className='position-relative'
      id='investimentos-idtvm'
      role='img'
      aria-label='Imagem de capa de fundos Investimentos IDTVM'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-9 col-lg-7'>
            <div className='d-flex align-items-center mb-3 pl-md-1'>
              <LogoInterInvest width='100' height='30' color='white' />
            </div>
            <h1 className='text-white fs-30 fs-md-50 fw-600'>Fundos de investimentos</h1>
          </div>
        </div>
      </div>
    </SectionDTVM>
  )
}

export default HeroDTVM
